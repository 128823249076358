import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const FormEditCompanyJenis = () => {
    const [name, setName] = useState("");
    const [desk, setDesk] = useState("");
    const [msg, setMsg] = useState("");
    const navigate = useNavigate();
    const { id } = useParams();
    const dataToPatch = {
        name: name,
        desk: desk
    };

    useEffect(() => {
        const getCompanyJenisById = async () => {
            try {
                const response = await axios.get(`https://backendas.agnisurya.com/companyJenis/${id}`);
                setName(response.data.name);
                setDesk(response.data.desk);

            } catch (error) {
                if (error.response) {
                    setMsg(error.response.data.msg);
                }
            }
        }
        getCompanyJenisById();
    }, [id]);

    const updateCompanyJenis = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`https://backendas.agnisurya.com/companyJenis/${id}`, dataToPatch);
            navigate("/companyJenis");
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    };
    return (
        <div>
            <h1 className="title">Company Jenis</h1>
            <h2 className="subtitle">Update Company Jenis</h2>
            <div className="card is-shadowsless">
                <div className="card-content">
                    <div className="content">
                        <form onSubmit={updateCompanyJenis}>
                            <p className="has-text-centered" >{msg}</p>
                            <div className="field">
                                <label className="label">Nama Jenis</label>
                                <div className="control">
                                    <input
                                        type="text"
                                        className="input"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        placeholder="Nama Jenis"
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Deskripsi</label>
                                <div className="control">
                                    <input
                                        type="text"
                                        className="input"
                                        value={desk}
                                        onChange={(e) => setDesk(e.target.value)}
                                        placeholder="Deskripsi"
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <div className="control">
                                    <button type="submit" className="button is-success">
                                        Update
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormEditCompanyJenis;
