import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

const initialState = {
  user: null,
  isAuthenticated: false,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const LoginUser = createAsyncThunk(
  "user/LoginUser",
  async (user, thunkApi) => {
    try {
      const response = await axios.post(
        `${apiUrl}/login`,
        {
          email: user.email,
          password: user.password,
        }
        );
      return response.data;
    } catch (error) {
      if (error.response) {
        const message = error.response.data.msg;
        return thunkApi.rejectWithValue(message);
      }
    }
  }
);

export const getMe = createAsyncThunk("user/getMe", async (_, thunkApi) => {
  try {
    const response = await axios.get(`${apiUrl}/me`);
    return response.data;
  } catch (error) {
    if (error.response) {
      const message = error.response.data.msg;
      return thunkApi.rejectWithValue(message);
    }
  }
});

export const LogOut = createAsyncThunk("user/LogOut", async () => {
  await axios.delete(`${apiUrl}/logout`);
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      return {
        ...initialState,
        isAuthenticated: state.isAuthenticated, // Tetap pertahankan status isAuthenticated
      };
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
  },
  extraReducers: (builders) => {
    builders.addCase(LoginUser.pending, (state) => {
      state.isLoading = true;
    });
    builders.addCase(LoginUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isAuthenticated = true;
      state.user = action.payload;
    });
    builders.addCase(LoginUser.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    });

    //Get User Login
    builders.addCase(getMe.pending, (state) => {
      state.isLoading = true;
    });
    builders.addCase(getMe.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isAuthenticated = true; 
      state.user = action.payload;
    });
    builders.addCase(getMe.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    });
    builders.addCase(LogOut.fulfilled, (state) => {
      return {
        ...initialState,
        isAuthenticated: false, // Set isAuthenticated menjadi false ketika logout berhasil
      };
    });
  },
});

export const { reset, setUser, setAuthenticated  } = authSlice.actions;
export default authSlice.reducer;
