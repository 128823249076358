import React, { useEffect } from 'react'
import Layout from '../../layout'
import FormEditProductSatuan from '../../../components/products/productsatuan/FormEditProductSatuan'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe } from "../../../features/authSlice";

const EditProductSatuan = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isError } = useSelector((state => state.auth));

    useEffect(() => {
        dispatch(getMe());
    }, [dispatch]);

    useEffect(() => {
        if (isError) {
            navigate("/");
        }
    }, [isError, navigate]);

    return (
        <div>
            <Layout>
                <FormEditProductSatuan />
            </Layout>
        </div>
    )
}

export default EditProductSatuan