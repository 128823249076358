import React, { useState, useEffect } from "react";
import Modal from 'react-modal';
import axios from "axios";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";

const FormSales = () => {

    let subtitle;

    const [modalIsOpen, setIsOpen] = useState(false);
    const [products, setProduct] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [pages, setPages] = useState(0);
    const [rows, setRows] = useState(0);
    const [keyword, setKeyword] = useState("");
    const [query, setQuery] = useState("");
    const [msg, setMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [cart, setCart] = useState([]);

    const [nosale, setNoSale] = useState("");
    const [tglsale, setTglSale] = useState("");
    const [total, setTotal] = useState(0);
    const [productId, setProductId] = useState("");
    const [price, setPrice] = useState(0);
    const [qty, setQty] = useState(0);
    const [countSale, setCountSale] = useState(0);
    const navigate = useNavigate();

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    <Modal className="setAppElement"></Modal>;

    function openModal(id, hr, qt) {
        setProductId(id);
        setQty(qt);
        setPrice(hr);
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }
    function closeModal() {
        setIsOpen(false);
    }

    useEffect(() => {
        setIsLoading(true);
        getProducts();
        getCount();
        setIsLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, keyword, countSale]);
    // page, keyword

    useEffect(() => {
        let newTotal = 0;
        cart.forEach(icart => {
            newTotal = newTotal + parseInt(icart.totalAmount);
        })
        setTotal(newTotal);
    }, [cart]);

    async function getProducts() {
        const response = await axios.get(
            `https://backendas.agnisurya.com/products?search_query=${keyword}&page=${page}&limit=${limit}`
        );
        setProduct(response.data.result);
        setPage(response.data.page);
        setLimit(response.data.limit);
        setPages(response.data.totalPage);
        setRows(response.data.totalRows);
    }

    async function getCount() {
        // const response = await axios.get('https://backendas.agnisurya.com/products');
        const response = await axios.get(
            `https://backendas.agnisurya.com/sale`
        );
        setCountSale(response.data.count);
        setNoSale(response.data.no_sale);
        getNoSale(countSale);
    }

    function getNoSale(e) {
        let nosale1 = 0;
        let nomor;
        nosale1 = parseFloat(e + 1).toString().padStart(6, '0');
        let date = new Date(), bln = "" + (date.getMonth() + 1), thn = "" + (date.getFullYear());
        let date1 = new Date();

        if (bln.length < 2) {
            bln = '0' + bln
        }

        // nopo.toString().padStart(6, '0')
        nomor = 'AS' + [thn + bln] + parseFloat(nosale1).toString().padStart(6, '0');

        setNoSale(nomor);
        setTglSale(date1.getFullYear() + "-" + (date1.getMonth() + 1) + "-" + date1.getDate());
        // setNoPO1('PO' + [thn + bln + day] + parseFloat(nopo1).toString().padStart(6, '0'));
    }

    const changePage = ({ selected }) => {
        setPage(selected);
        if (selected === 9) {
            setMsg(
                "Jika tidak menemukan data yang anda cari, silahkan cari dengan kata kunci yang lebih Spesifik !"
            );
        } else {
            setMsg("");
        }
    };

    const searchData = (e) => {
        e.preventDefault();
        setPage(0);
        setKeyword(query);
    };

    const addProductToCart = async (prod) => {
        let findProductInCart = await cart.find(i => {
            return i.id === prod.id;
        });

        if (findProductInCart) {
            let newCart = [];
            let newItem;

            cart.forEach(cartItem => {
                if (cartItem.id === prod.id) {
                    newItem = {
                        ...cartItem,
                        quantity: parseInt(cartItem.quantity) + 1,
                        totalAmount: parseInt(cartItem.price) * (parseInt(cartItem.quantity) + 1),
                    }
                    newCart.push(newItem);
                } else {
                    newCart.push(cartItem);
                }
            });
            setCart(newCart);
        } else {
            let addingProduct = {
                ...prod,
                // 'id': prod.value,
                // 'kdbrg': prod.kdbrg,
                // 'name': prod.label,
                'satuan': prod.product_satuan.name,
                'price': prod.price,
                'quantity': 1,
                'totalAmount': prod.price
            }
            setCart([...cart, addingProduct]);

        }
    }

    const handleChangeQty = async (id, hr, qt) => {
        let findProductInCart = await cart.find(i => {
            return i.id === id;
        });

        let newCart = [];
        let newItem;

        if (findProductInCart) {
            cart.forEach(cartItem => {
                if (cartItem.id === id) {
                    newItem = {
                        ...cartItem,
                        quantity: parseInt(qt),
                        price: parseInt(hr),
                        totalAmount: parseInt(hr) * parseInt(qt),
                    }
                    newCart.push(newItem);
                }
                else {
                    newCart.push(cartItem);
                }
            });
            setCart(newCart);
        }
    }

    const saveSale = async (e) => {
        e.preventDefault();

        let nilyar = 0;
        let jenisbyr = 0;
        let bank = "nobank";
        const formData = new FormData();
        const formData1 = new FormData();
        formData1.append("no_sale", nosale);
        formData1.append("tgl_sale", tglsale);
        formData1.append("nilai_sale", total);
        formData1.append("nilai_bayar", nilyar);
        formData1.append("jenis_bayar", jenisbyr);
        formData1.append("bank", bank);

        cart.forEach(async icart => {
            let nosale1 = nosale;

            formData.append("productId", icart.id);
            formData.append("satuan", icart.satuan);
            formData.append("qty", icart.quantity);
            formData.append("price", icart.price);
            formData.append("jumlah", icart.totalAmount);
            formData.append("no_sale", nosale1);
        })

        try {


            await axios.post("https://backendas.agnisurya.com/saldet", formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });

            await axios.post("https://backendas.agnisurya.com/sale", formData1, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });

            navigate("/sale");
            window.location.reload(false);

        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    };

    const removeProduct = async (prod) => {
        const newCart = cart.filter(cartItem => cartItem.id !== prod.id);
        setCart(newCart);
    }

    return (
        <div>
            <h1 className="title">AGNI SURYA</h1>
            <h2 className="subtitle">Electric & Electronic Supplies</h2>
            <div className="box">
                <form onSubmit={searchData}>
                    <div className="field has-addons">
                        <div className="control is-expanded">
                            <input
                                type="text"
                                className="input"
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                                placeholder="Find something here ..."
                            ></input>
                        </div>
                        <div className="control">
                            <button type="submit" className="button is-info">
                                Search
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div className="columns is-mobile">
                <div className="column is-7">
                    <div className="box">
                        {isLoading ? 'Loading ...' :
                            <div className="columns is-mobile is-multiline">
                                {products.map((prd) => (
                                    <div className="column is-one-quarter" key={prd.id}>
                                        <div className="card" onClick={() => addProductToCart(prd)}>
                                            <div className="card-image">
                                                <figure className="image is-4by3">
                                                    <img src={prd.url} alt={prd.name} />
                                                </figure>
                                            </div>
                                            <div className="card-content">
                                                <div className="media">
                                                    <div className="media-content">
                                                        <p className="title is-4">{prd.name}</p>
                                                        <p className="subtitle is-6 is-bold">Rp.{prd.price}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>}
                    </div>
                    <p>
                        Total Rows : {rows} Page : {rows ? page + 1 : 0} of {pages}
                    </p>
                    <p className="has-text-centered has-text-danger">{msg}</p>
                    <nav
                        className="pagination is-centered"
                        key={rows}
                        role="navigation"
                        aria-label="pagination"
                    >
                        <ReactPaginate
                            previousLabel={"< Prev"}
                            nextLabel={"Next"}
                            pageCount={Math.min(10, pages)}
                            onPageChange={changePage}
                            containerClassName={"pagination-list"}
                            pageLinkClassName={"pagination-link"}
                            previousLinkClassName={"pagination-previous"}
                            nextLinkClassName={"pagination-next"}
                            activeLinkClassName={"pagination-link is-current"}
                            disabledLinkClassName={"pagination-link is-disabled"}
                        />
                    </nav>
                </div>
                <div className="column is-half">
                    <div className="box">
                        <div className="columns is-mobile">
                            <div className="column is-half">
                                <div className="table-responsive bg-dark">
                                    <table className="table table-responsive table-dark table-hover">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Nama Produk</th>
                                                <th>Satuan</th>
                                                <th>Price</th>
                                                <th>Qty</th>
                                                <th>Jumlah</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {cart ? cart.map((prd, index) =>
                                                <tr key={prd.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{prd.name}</td>
                                                    <td>{prd.satuan}</td>
                                                    <td>{prd.price}</td>
                                                    <td>{prd.quantity}</td>
                                                    <td>{prd.totalAmount}</td>
                                                    <td>
                                                        <div className="field is-grouped">
                                                            <p className="control">
                                                                <button type="button" className="button is small is-warning" onClick={() => openModal(prd.id, prd.price, prd.quantity)}>
                                                                    Edit
                                                                </button>
                                                            </p>
                                                            <p className="control">
                                                                <button type="button" className="button is small is-danger" onClick={() => removeProduct(prd)}>
                                                                    Remove
                                                                </button>
                                                            </p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ) : 'Tidak ada Data di Cart'}
                                        </tbody>
                                    </table>
                                    <h2 className="title">Total : Rp. {total}</h2>
                                </div>
                                <div className="mt-3">
                                    {

                                        total !== 0 ? <div>
                                            <div className="field is-grouped">
                                                <p className="control">
                                                    <button type="button" className="button is small is-primary" onClick={saveSale}>
                                                        Save
                                                    </button>
                                                </p>
                                            </div>
                                        </div> : "silahkan Masukan Produk !"
                                    }
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            {/* *** MODAL *** */}

            <form>
                <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                    ariaHideApp={false}
                >

                    <h1 className="title" ref={(_subtitle) => (subtitle = _subtitle)}>Products</h1>
                    <h2 className="subtitle" ref={(_subtitle) => (subtitle = _subtitle)}>Edit Price & Qty Product</h2>
                    <form>
                        <div className="field has-addons">
                            <div className="control is-expanded">
                                <input
                                    type="text"
                                    className="text"
                                    value={productId}
                                    onChange={(e) => setProductId(e.target.value)}
                                ></input>
                            </div>
                            <div className="control is-expanded">
                                <input
                                    type="text"
                                    className="input"
                                    value={price}
                                    onChange={(e) => setPrice(e.target.value)}
                                ></input>
                            </div>
                            <div className="control is-expanded">
                                <input
                                    type="text"
                                    className="input"
                                    value={qty}
                                    onChange={(e) => setQty(e.target.value)}
                                ></input>
                            </div>
                            <div className="field">
                                <div className="control">
                                    <button type="button" className="button is-success" onClick={() => handleChangeQty(productId, price, qty)}>
                                        Select
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <button type="button" className="button is-danger mt-3 mb-2 mr-2" onClick={closeModal}>close</button>
                </Modal>
            </form>
        </div>
    )
}

export default FormSales