import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from 'react-modal';
import Select from 'react-select'
import { useParams, useNavigate } from "react-router-dom";


const FormEditPurchaseOrder = () => {

    let subtitle;

    // const [podet, setPodet] = useState([]);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [products, setProduct] = useState([]);
    // const [keyword, setKeyword] = useState("");
    // const [query, setQuery] = useState("");
    const [suppliers, setSuppliers] = useState([]);
    const [pilihSpl, setPilihSpl] = useState();
    const [nopo, setNoPO] = useState("");
    const [tglpo, setTglPO] = useState("");
    const [total, setTotal] = useState(0);
    // const [nopo1, setNoPO1] = useState("");

    const [productId, setProductId] = useState("");
    // const [satuan, setSatuan] = useState("");
    const [hrgBeli, setHrgBeli] = useState(0);
    const [qty, setQty] = useState(0);
    // const [jmlHrg, setJmlHrg] = useState(0);
    // const [qtyTerima, setQtyTerima] = useState(0);

    const [cart, setCart] = useState([]);
    const navigate = useNavigate();
    const [msg, setMsg] = useState("");

    const { id } = useParams();


    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    <Modal className="setAppElement"></Modal>;

    function openModal(id, hr, qt) {
        setProductId(id);
        setQty(qt);
        setHrgBeli(hr);
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }
    function closeModal() {
        setIsOpen(false);
    }


    useEffect(() => {
        const getPoById = async () => {
            try {
                const response = await axios.get(`https://backendas.agnisurya.com/po/${id}`);
                setNoPO(response.data.no_po);
                setTglPO(response.data.tgl_po);
                setPilihSpl(response.data.supplierId);
                setTotal(response.data.nilai_po);
            } catch (error) {
                if (error.response) {
                    setMsg(error.response.data.msg);
                }
            }
        }
        getPoById();
        getSuppliers();
        getProducts();
        getPurchaseOrderDetil();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        let newTotal = 0;
        cart.forEach(icart => {
            newTotal = newTotal + parseInt(icart.jumlah);
        })
        setTotal(newTotal);
    }, [cart]);

    async function getPurchaseOrderDetil() {

        const prod = await axios.get(`https://backendas.agnisurya.com/podet/${id}`);
        setCart(prod.data.result);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    async function getSuppliers() {
        const response = await axios.get(`https://backendas.agnisurya.com/supplier`);
        setSuppliers(response.data.result);

    }

    async function getProducts() {
        // const response = await axios.get('https://backendas.agnisurya.com/products');
        const response = await axios.get(
            `https://backendas.agnisurya.com/products`
        );
        setProduct(response.data.result);
    }


    const handleChangeQty = async (id, hr, qt) => {
        let findProductInCart = await cart.find(i => {
            return i.id === id;
        });

        let newCart = [];
        let newItem;

        if (findProductInCart) {
            cart.forEach(cartItem => {
                if (cartItem.id === id) {
                    newItem = {
                        ...cartItem,
                        qty: parseInt(qt),
                        hrg_beli: parseInt(hr),
                        jumlah: parseInt(hr) * parseInt(qt),
                    }
                    newCart.push(newItem);
                }
                else {
                    newCart.push(cartItem);
                }
            });
            setCart(newCart);
        }
    }

    // const addProductToCartFormBarcode = async (prod) => {
    //     let findProductInCart = await cart.find(i => {
    //         return i.id === prod.value;
    //     });

    //     if (findProductInCart) {
    //         setMsg("Data Sudah Ada !!!");

    //     } else {
    //         let addingProduct = {
    //             ...prod,
    //             'id': prod.value,
    //             'kd_brg': prod.kd_brg,
    //             'name': prod.label,
    //             'satuan': prod.satuan,
    //             'price': prod.price,
    //             'quantity': 1,
    //             'totalAmount': prod.price
    //         }
    //         setCart([...cart, addingProduct]);
    //     }
    //     // savePODetil(prod.value, prod.satuan, prod.price, prod.quantity, prod.totalAmount);
    // }

    // async function getProducts() {
    //     // const response = await axios.get('https://backendas.agnisurya.com/products');
    //     const response = await axios.get(
    //         `https://backendas.agnisurya.com/products`
    //     );
    //     setProduct(response.data.result);
    // }

    const optionProduct = products ? products.map((prd) => {
        return (
            { value: prd.id, kd_brg: prd.kd_brg, label: prd.name, satuan: prd.product_satuan.name, price: prd.hrg_beli, quantity: prd.qty }
        );
    }) : null;

    const updatePO = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        const formData1 = new FormData();

        formData1.append("tgl_po", tglpo);
        formData1.append("supplierId", pilihSpl);
        formData1.append("nilai_po", total);

        cart.forEach(async icart => {
            formData.append("id", icart.id);
            // formData.append("productId", icart.value);
            formData.append("satuan", icart.satuan);
            formData.append("qty", icart.qty);
            formData.append("hrg_beli", icart.hrg_beli);
            formData.append("jumlah", icart.jumlah);
        })

        try {

            await axios.post(`https://backendas.agnisurya.com/podet/${id}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });

            await axios.post(`https://backendas.agnisurya.com/po/${id}`, formData1, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });

            navigate("/po");
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    };

    const deletePODetil = async (poId) => {
        await axios.delete(`https://backendas.agnisurya.com/podet/${poId}`);
        getPurchaseOrderDetil();
    };

    // const savePODetil = async (e, value, satuan, price, quantity, totalAmount) => {
    //     e.preventDefault();
    //     let qtyter = 0;
    //     const formData = new FormData();

    //     formData.append("productId", value);
    //     formData.append("satuan", satuan);
    //     formData.append("qty", quantity);
    //     formData.append("hrg_beli", price);
    //     formData.append("jumlah", totalAmount);
    //     formData.append("qty_terima", qtyter);
    //     formData.append("no_po", nopo);

    //     try {
    //         await axios.post("https://backendas.agnisurya.com/podet", formData, {
    //             headers: {
    //                 "Content-Type": "multipart/form-data"
    //             }
    //         });

    //     } catch (error) {
    //         if (error.response) {
    //             setMsg(error.response.data.msg);
    //         }
    //     }
    // };

    return (
        <div>
            <h1 className="title">Purchase Order</h1>
            <h2 className="subtitle">Edit Purchase Order</h2>
            <form onSubmit={updatePO}>
                <p className="has-text.centered">{msg}</p>
                <div className="columns">
                    <div className="column">
                        <div className="box">
                            <div className="field">
                                <label className="label">No. PO</label>
                                <div className="control">
                                    <input
                                        type="text"
                                        className="input"
                                        value={nopo}
                                        onChange={(e) => setNoPO(e.target.value)}
                                        placeholder="No. PO"
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Tgl. PO</label>
                                <div className="control">
                                    <input
                                        type="date"
                                        className="input"
                                        value={tglpo}
                                        onChange={(e) => setTglPO(e.target.value)}
                                        placeholder="Tgl. PO"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column">
                        <div className="box">
                            <div className="field">
                                <label className="label">Supplier</label>
                                <div className="control">
                                    <div className="select is-fullwidth">
                                        <select value={pilihSpl} onChange={(e) => setPilihSpl(e.target.value)}>
                                            {/* <option value="default">Pilih Supplier</option> */}
                                            {suppliers.map((spl) => {
                                                return (
                                                    <option key={spl.id} value={spl.id}>{spl.name}</option>
                                                );
                                            })
                                            }
                                        </select>
                                    </div>
                                    {/* <Select value={optionSupplier.value} onChange={(e) => setPilihSpl(e.target.value)} option={optionSupplier} /> */}
                                </div>
                            </div>
                            <div>
                                <h2 className='subtitle ri-separator' > Total : Rp.{total}</h2>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="columns">
                    <div className="column .is-narrow-fullhd">
                        <div className="box">
                            <Select options={optionProduct} />
                            {/* onChange={(e) => addProductToCartFormBarcode(e)}  */}
                        </div>
                    </div>
                </div>

                <div className="columns">
                    <div className="column .is-narrow-fullhd">
                        <div className="box">
                            <table className="table is-striped is-fullwidth">
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Kd. Brg</th>
                                        <th>Nama Produk</th>
                                        <th>Satuan</th>
                                        <th>Price</th>
                                        <th>Qty</th>
                                        <th>Jumlah</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        cart ? cart.map((prd, index) =>
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{prd.product.kd_brg}</td>
                                                <td>{prd.product.name}</td>
                                                <td>{prd.satuan}</td>
                                                <td>{prd.hrg_beli}</td>
                                                <td>{prd.qty}</td>
                                                <td>{prd.jumlah}</td>
                                                <td>
                                                    <div className="field is-grouped">
                                                        <p className="control">
                                                            <button type="button" className="button is small is-warning" onClick={() => openModal(prd.id, prd.hrg_beli, prd.qty)}>
                                                                Edit
                                                            </button>
                                                        </p>
                                                        <p className="control">
                                                            <button type="button" className="button is small is-danger" onClick={() => deletePODetil(prd.id)}>
                                                                Remove
                                                            </button>
                                                        </p>
                                                    </div>
                                                </td>
                                            </tr>

                                        ) :
                                            <tr>
                                                <td colSpan="4" className="text-center">
                                                    <div className="alert alert-danger mb-0">
                                                        Data Belum Tersedia!
                                                    </div>
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="field">
                    <div className="control">
                        <button type="submit" className="button is-success" >
                            Update
                        </button>
                    </div>
                </div>
            </form>

            {/* *** MODAL *** */}

            <form>
                <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                    ariaHideApp={false}
                >

                    <h1 className="title" ref={(_subtitle) => (subtitle = _subtitle)}>Products</h1>
                    <h2 className="subtitle" ref={(_subtitle) => (subtitle = _subtitle)}>Edit Price & Qty Product</h2>
                    <form>
                        <div className="field has-addons">
                            <div className="control is-expanded">
                                <label
                                    type="text"
                                    className="text"
                                    value={productId}
                                    onChange={(e) => setProductId(e.target.value)}
                                ></label>
                            </div>
                            <div className="control is-expanded">
                                <input
                                    type="text"
                                    className="input"
                                    value={hrgBeli}
                                    onChange={(e) => setHrgBeli(e.target.value)}
                                ></input>
                            </div>
                            <div className="control is-expanded">
                                <input
                                    type="text"
                                    className="input"
                                    value={qty}
                                    onChange={(e) => setQty(e.target.value)}
                                ></input>
                            </div>
                            <div className="field">
                                <div className="control">
                                    <button type="button" className="button is-success" onClick={() => handleChangeQty(productId, hrgBeli, qty)}>
                                        Select
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <button type="button" className="button is-danger mt-3 mb-2 mr-2" onClick={closeModal}>close</button>
                </Modal>
            </form>

        </div>
    )
}

export default FormEditPurchaseOrder