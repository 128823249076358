import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const FormAddCustomer = () => {
    const [name, setName] = useState("");
    const [company_name, setCompanyName] = useState("");
    const [alamat, setAlamat] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [piutang, setPiutang] = useState("");
    const [desk, setDesk] = useState("");
    const [msg, setMsg] = useState("");
    const navigate = useNavigate();

    const saveCustomer = async (e) => {
        e.preventDefault();
        try {
            await axios.post("https://backendas.agnisurya.com/customer", {
                name: name,
                company_name: company_name,
                alamat: alamat,
                phone: phone,
                email: email,
                piutang: piutang,
                desk: desk,
            });
            navigate("/customer");
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    };

    return (
        <div>
            <h1 className="title">Customer</h1>
            <h2 className="subtitle">Add New Customer</h2>
            <div className="card is-shadowsless">
                <div className="card-content">
                    <div className="content">
                        <form onSubmit={saveCustomer}>
                            <p className="has-text.centered">{msg}</p>
                            <div className="field">
                                <label className="label">Nama Customer</label>
                                <div className="control">
                                    <input
                                        type="text"
                                        className="input"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        placeholder="Nama Jenis"
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Company Name</label>
                                <div className="control">
                                    <input
                                        type="text"
                                        className="input"
                                        value={company_name}
                                        onChange={(e) => setCompanyName(e.target.value)}
                                        placeholder="Company Name"
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Alamat</label>
                                <div className="control">
                                    <input
                                        type="text"
                                        className="input"
                                        value={alamat}
                                        onChange={(e) => setAlamat(e.target.value)}
                                        placeholder="Alamat"
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Phone</label>
                                <div className="control">
                                    <input
                                        type="text"
                                        className="input"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        placeholder="Phone"
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Email</label>
                                <div className="control">
                                    <input
                                        type="text"
                                        className="input"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder="Email"
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Piutang</label>
                                <div className="control">
                                    <input
                                        type="text"
                                        className="input"
                                        value={piutang}
                                        onChange={(e) => setPiutang(e.target.value)}
                                        placeholder="Piutang"
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Deskripsi</label>
                                <div className="control">
                                    <input
                                        type="text"
                                        className="input"
                                        value={desk}
                                        onChange={(e) => setDesk(e.target.value)}
                                        placeholder="Deskripsi"
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <div className="control">
                                    <button type="submit" className="button is-success">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormAddCustomer;
