import React, { useEffect } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Dashboard from "./pages/dashboard";
import Login from "./components/Login";
import Users from "./pages/users/Users";
import Products from "./pages/products/Products";
import AddUser from "./pages/users/AddUser";
import EditUser from "./pages/users/EditUser";
import AddProduct from "./pages/products/AddProduct";
import EditProduct from "./pages/products/EditProduct";
import ProductJenis from "./pages/products/productjenis/ProductJenis";
import AddProductJenis from "./pages/products/productjenis/AddProductJenis";
import EditProductJenis from "./pages/products/productjenis/EditProductJenis";
import ProductSatuan from "./pages/products/productsatuan/ProductSatuan";
import AddProductSatuan from "./pages/products/productsatuan/AddProductSatuan";
import EditProductSatuan from "./pages/products/productsatuan/EditProductSatuan";
import CompanyJenis from "./pages/company/companyjenis/CompanyJenis";
import AddCompanyJenis from "./pages/company/companyjenis/AddCompanyJenis";
import EditCompanyJenis from "./pages/company/companyjenis/EditCompanyJenis";
import Company from "./pages/company/Company";
import AddCompany from "./pages/company/AddCompany";
import EditCompany from "./pages/company/EditCompany";
import Customer from "./pages/customer/Customers";
import AddCustomer from "./pages/customer/AddCustomer";
import EditCustomer from "./pages/customer/EditCustomer";
import Supplier from "./pages/supplier/Suppliers";
import AddSupplier from "./pages/supplier/AddSupplier";
import EditSupplier from "./pages/supplier/EditSupplier";
import Karyawan from "./pages/karyawan/Karyawan";
import AddKaryawan from "./pages/karyawan/AddKaryawan";
import EditKaryawan from "./pages/karyawan/EditKaryawan";
import PurchaseOrders from "./pages/purchaseorder/PurchaseOrders";
import AddPurchaseOrders from "./pages/purchaseorder/AddPurchaseOrder";
import EditPurchaseOrder from "./pages/purchaseorder/EditPurchaseOrder";
import Sales from "./pages/cashregister/Sales";
import Kasir from "./pages/cashregister/Kasir";
import TerimaPO from "./pages/warehouse/po/TerimaPO";
import StockOpname from "./pages/warehouse/stock/StockOpname";
import JenisBayar from "./pages/jenisbayar/JenisBayar";
import AddJenisBayar from "./pages/jenisbayar/AddJenisBayar";
import EditJenisBayar from "./pages/jenisbayar/EditJenisBayar";
import Bank from "./pages/bank/Bank";
import AddBank from "./pages/bank/AddBank";
import EditBank from "./pages/bank/EditBank";
import AddTerimaPO from "./pages/warehouse/po/AddTerimaPO";
import AddPOInvoice from "./pages/purchaseorder/AddPOInvoice";
import InvoicePO from "./pages/purchaseorder/InvoicePO";
import useAuthMiddleware from "./app/authMiddleware";

function PrivateRoute({ element, isAuth, fallbackPath }) {
    return isAuth ? element : <Navigate to={fallbackPath} replace />;
}
  
function Routers() {
    const navigate = useNavigate();
    const { checkAuth, backendError, setBackendError } = useAuthMiddleware();
    const { user,isLoading } = useSelector(
        (state) => state.auth
    );
    
    useEffect(() => {
        const handleAuthStatus = async () => {
            try {
              // Check backend connection
              await checkAuth();
            } catch (error) {
              console.error('Error checking authentication status:', error);
              setBackendError('An error occurred while checking authentication status.');
            }
          };
           // Call the function to handle authentication status
        handleAuthStatus();
    }, []);

    return (
        <div>
            {backendError && (
                <div className="modal is-active">
                    <div className="modal-background"></div>
                    <div className="modal-content">
                        <div className="box">
                            <p>{backendError}</p>
                        </div>
                    </div>
                    <button
                        className="modal-close is-large"
                        aria-label="close"
                        onClick={() => setBackendError(null)}
                    ></button>
                </div>
            )}
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/dashboard" element={<Dashboard />} />
                {/* <Route
                    path="/dashboard"
                    element={<PrivateRoute element={<Dashboard />} isAuth={user} fallbackPath="/" />}
                /> */}
                <Route path="/users" element={<Users />} />
                <Route path="/users/add" element={<AddUser />} />
                <Route path="/users/edit/:id" element={<EditUser />} />
                <Route path="/products" element={<Products />} />
                <Route path="/products/add" element={<AddProduct />} />
                <Route path="/products/edit/:id" element={<EditProduct />} />
                <Route path="/productjenis" element={<ProductJenis />} />
                <Route path="/productjenis/add" element={<AddProductJenis />} />
                <Route path="/productjenis/edit/:id" element={<EditProductJenis />} />
                <Route path="/productsatuan" element={<ProductSatuan />} />
                <Route path="/productsatuan/add" element={<AddProductSatuan />} />
                <Route
                    path="/productsatuan/edit/:id"
                    element={<EditProductSatuan />}
                />
                <Route path="/companys" element={<Company />} />
                <Route path="/companys/add" element={<AddCompany />} />
                <Route path="/companys/edit/:id" element={<EditCompany />} />
                <Route path="/companyjenis" element={<CompanyJenis />} />
                <Route path="/companyjenis/add" element={<AddCompanyJenis />} />
                <Route path="/companyjenis/edit/:id" element={<EditCompanyJenis />} />
                <Route path="/customer" element={<Customer />} />
                <Route path="/customer/add" element={<AddCustomer />} />
                <Route path="/customer/edit/:id" element={<EditCustomer />} />
                <Route path="/supplier" element={<Supplier />} />
                <Route path="/supplier/add" element={<AddSupplier />} />
                <Route path="/supplier/edit/:id" element={<EditSupplier />} />
                <Route path="/karyawan" element={<Karyawan />} />
                <Route path="/karyawan/add" element={<AddKaryawan />} />
                <Route path="/karyawan/edit/:id" element={<EditKaryawan />} />
                <Route path="/po" element={<PurchaseOrders />} />
                <Route path="/po/add" element={<AddPurchaseOrders />} />
                <Route path="/po/edit/:id" element={<EditPurchaseOrder />} />
                <Route path="/poinv" element={<InvoicePO />} />
                <Route path="/poinv/add" element={<AddPOInvoice />} />
                <Route path="/sale" element={<Sales />} />
                <Route path="/kasir" element={<Kasir />} />
                <Route path="/poterima" element={<TerimaPO />} />
                <Route path="/poterima/add" element={<AddTerimaPO />} />
                <Route path="/stokopname" element={<StockOpname />} />
                <Route path="/jenisbyr" element={<JenisBayar />} />
                <Route path="/jenisbyr/add" element={<AddJenisBayar />} />
                <Route path="/jenisbyr/edit/:id" element={<EditJenisBayar />} />
                <Route path="/bank" element={<Bank />} />
                <Route path="/bank/add" element={<AddBank />} />
                <Route path="/bank/edit/:id" element={<EditBank />} />
            </Routes>

        </div>
    );
}

export default Routers;
