import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoginUser, reset, setAuthenticated, setUser } from "../features/authSlice";
import logo from "../brandas.png";
const apiUrl = process.env.REACT_APP_API_URL;

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user, isError, isSuccess, isLoading, message, isAuthenticated } = useSelector(
        (state) => state.auth
    );
    const [backendError, setBackendError] = useState(null);

    useEffect(() => {
        if (isAuthenticated && user) {
            navigate("/dashboard");
            dispatch(reset());
          } else if (isError) {
            setErrorMessage(message);
            dispatch(reset());
          }
        // if (user || isSuccess) {
        //     navigate("/dashboard");
        // }
        // dispatch(reset());
    }, [isAuthenticated, isError, user, isSuccess, dispatch, navigate, message]);

    const validateEmail = (inputEmail) => {
        // Regex untuk validasi email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(inputEmail);
      };

    const Auth = async (e) => {
        e.preventDefault();
        
        // Validasi email dan password tidak boleh kosong
        if (!email || !password) {
            setErrorMessage("Email dan password tidak boleh kosong.");
            dispatch(reset());
            return;
        }
    
        // Validasi format email
        if (!validateEmail(email)) {
            setErrorMessage("Format email tidak valid.");
            dispatch(reset());
            return;
        }
        
         // cek server availability
        const checkBackendConnection = async () => {
            try {
            // Ganti URL backend dengan URL sesuai backend Anda
            await fetch(apiUrl);
            // Jika koneksi berhasil, set state error menjadi null
            setErrorMessage(null);
            } catch (error) {
            // Jika terjadi kesalahan, tangani kesalahan di sini
            // console.error("Kesalahan koneksi ke backend:", error);
            setErrorMessage("Gagal terhubung ke server. Silakan coba lagi nanti.");
            }
        };
        
        // Pemanggilan fungsi checkBackendConnection
        await checkBackendConnection();

        // Cek apakah ada kesalahan koneksi ke backend
        if (backendError) {
        return; // Hentikan proses login jika terdapat kesalahan koneksi
        }

        dispatch(LoginUser({ email, password })).then((result) => {
            if (result.payload) {
              dispatch(setAuthenticated(true));
              dispatch(setUser(result.payload));  // Make sure setUser is called here
              navigate("/dashboard");
            }
          });
    }
    return (
        <section className="hero has-background-grey-light is-fullheight is-fullwidth">
            <div className="hero-body">
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column is-4 is-center">
                            <img src={logo} width="442" height="228" alt="logo" />
                            <form onSubmit={Auth} className="box">
                                {isError && (
                                    <div className="notification is-danger is-light has-text-centered">
                                        {message}
                                    </div>
                                )}
                                {errorMessage && (
                                    <div className="notification is-danger is-light has-text-centered">
                                        {errorMessage}
                                    </div>
                                )}
                                <h1 className="title is-2">Sign In</h1>
                                <div className="field">
                                    <label className="label">Email</label>
                                    <div className="control">
                                        <input
                                            type="email"
                                            className="input"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            placeholder="Email"
                                        />
                                    </div>
                                </div>
                                <div className="field">
                                    <label className="label">Password</label>
                                    <div className="control">
                                        <input
                                            type="password"
                                            className="input"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            placeholder="********"
                                        />
                                    </div>
                                </div>
                                <div className="field mt-5">
                                    <button type="submit" className="button is-success is-fullwidth">
                                        {isLoading ? 'Loading ....' : 'Login'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Login;
