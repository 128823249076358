import React from 'react'

const StockOpnameList = () => {
    return (
        <div>
            <h1 className="title">WAREHOUSE</h1>
            <h2 className="subtitle">STOCK OPNAME</h2>
            <div className="box">
                <form >
                    {/* onSubmit={searchData} */}
                    <div className="field has-addons">
                        <div className="control is-expanded">
                            <input
                                type="text"
                                className="input"
                                // value={query}
                                // onChange={(e) => setQuery(e.target.value)}
                                placeholder="Find something here ..."
                            ></input>
                        </div>
                        <div className="control">
                            <button type="submit" className="button is-info">
                                Search
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <table className="table is-striped is-fullwidth">
                <thead>
                    <tr>
                        <th>No</th>
                        <th>No. Tran</th>
                        <th>Tgl. Tran</th>
                        <th>Nilai Tran</th>
                        <th>Created By</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {/* {purchaseOrder.map((po, index) => (
                        <tr key={po.uuid}>
                            <td>{index + 1}</td>
                            <td>{po.no_po}</td>
                            <td>{po.tgl_po}</td>
                            <td>{po.nilai_po}</td>
                            <td>{po.supplier.name}</td>
                            <td>{po.user.name}</td>
                            <td>
                                <Link
                                    to={`/po/edit/${po.no_po}`}
                                    className="button is-small is-info"
                                >
                                    Edit
                                </Link>
                                <button
                                    onClick={() => deletePurchaseOrder(po.uuid)}
                                    className="button is-small is-danger"
                                >
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))} */}
                </tbody>
            </table>
        </div>
    )
}

export default StockOpnameList