import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const FormEditProductSatuan = () => {
    const [name, setName] = useState("");
    const [desk, setDesk] = useState("");
    const [msg, setMsg] = useState("");
    const { id } = useParams();
    const navigate = useNavigate();


    useEffect(() => {
        const getProductSatuanById = async () => {
            try {
                const response = await axios.get(`https://backendas.agnisurya.com/productSatuan/${id}`);
                setName(response.data.name);
                setDesk(response.data.desk);

            } catch (error) {
                if (error.response) {
                    setMsg(error.response.data.msg);
                }
            }
        }
        getProductSatuanById();
    }, [id]);

    // useEffect(() => {
    //     getProductJenisById();
    // }, []);

    // const getProductJenisById = async () => {
    //     const response = await axios.get(`https://backendas.agnisurya.com/productJenis/${id}`);
    //     setName(response.data.name);
    //     setDesk(response.data.desk);
    // }


    const updateProductSatuan = async (e) => {
        e.preventDefault();
        try {
            await axios.patch(`https://backendas.agnisurya.com/productSatuan/${id}`, {
                name: name,
                desk: desk
            });
            navigate("/productSatuan");
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    };
    return (
        <div>
            <h1 className="title">Product Satuan</h1>
            <h2 className="subtitle">Update Product Satuan</h2>
            <div className="card is-shadowsless">
                <div className="card-content">
                    <div className="content">
                        <form onSubmit={updateProductSatuan}>
                            <p className="has-text-centered" >{msg}</p>
                            <div className="field">
                                <label className="label">Nama Satuan</label>
                                <div className="control">
                                    <input
                                        type="text"
                                        className="input"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        placeholder="Nama Satuan"
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Deskripsi</label>
                                <div className="control">
                                    <input
                                        type="text"
                                        className="input"
                                        value={desk}
                                        onChange={(e) => setDesk(e.target.value)}
                                        placeholder="Deskripsi"
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <div className="control">
                                    <button type="submit" className="button is-success">Update</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormEditProductSatuan;
