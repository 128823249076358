import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal';


const FormAddTerimaPO = () => {
    let subtitle;
    const navigate = useNavigate();

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [pages, setPages] = useState(0);
    const [rows, setRows] = useState(0);
    const [keyword, setKeyword] = useState("");
    const [msg, setMsg] = useState("");

    const [queryNotTerima, setQueryNotTerima] = useState("");
    const [poNotTerima, setPONotTerima] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [pilihSpl, setPilihSpl] = useState();
    const [noterima, setNoTerima] = useState("");
    const [tglterima, setTglTerima] = useState("");
    const [nopo, setNoPO] = useState("");
    const [tglpo, setTglPO] = useState("");
    const [cart, setCart] = useState([]);

    const [modalIsOpen, setIsOpen] = useState(false);
    const [productId, setProductId] = useState("");
    const [qtyterima, setQtyTerima] = useState(0);
    // const [hrgbeli, setHrgBeli] = useState(0);
    const [countTerima, setCountTerima] = useState(0);
    // const { id } = useParams();

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    <Modal className="setAppElement"></Modal>;

    function openModal(id, qt) {
        setProductId(id);
        setQtyTerima(qt);
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }
    function closeModal() {
        setIsOpen(false);
    }

    useEffect(() => {
        // getPODetil();
        getPONotTerima();
        getCount();
        getSuppliers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, keyword, countTerima]);

    async function getCount() {
        // const response = await axios.get('https://backendas.agnisurya.com/products');
        const response = await axios.get(
            `https://backendas.agnisurya.com/poterima`
        );
        setCountTerima(response.data.count);
        getNoTerima(countTerima);
    }

    function getNoTerima(e) {
        let nopo1 = 0;
        nopo1 = parseFloat(e + 1).toString().padStart(6, '0');
        let date = new Date(), bln = "" + (date.getMonth() + 1), day = "" + (date.getDate()), thn = "" + (date.getFullYear());

        if (day.length < 2) {
            day = '0' + day
        }
        if (bln.length < 2) {
            bln = '0' + bln
        }

        // nopo.toString().padStart(6, '0')
        setNoTerima('Tr' + [thn + bln + day] + parseFloat(nopo1).toString().padStart(6, '0'));
        // setNoPO1('PO' + [thn + bln + day] + parseFloat(nopo1).toString().padStart(6, '0'));
    }

    async function getPONotTerima() {
        // const response = await axios.get('https://backendas.agnisurya.com/products');
        const response = await axios.get(
            `https://backendas.agnisurya.com/postatusterima/?search_query=${keyword}&page=${page}&limit=${limit}`
        );
        setPONotTerima(response.data.result);
        setPage(response.data.page);
        setLimit(response.data.limit);
        setPages(response.data.totalPage);
        setRows(response.data.totalRows);
    }

    const getPOTampil = async (poId) => {
        const response = await axios.get(`https://backendas.agnisurya.com/postatusterimatampil/${poId}`);
        let date = new Date(), bln = "" + (date.getMonth() + 1), day = "" + (date.getDate()), thn = "" + (date.getFullYear());

        if (day.length < 2) {
            day = '0' + day
        }
        if (bln.length < 2) {
            bln = '0' + bln
        }

        setTglTerima([thn + "-" + bln + "-" + day]);
        setNoPO(response.data.response.no_po);
        setTglPO(response.data.response.tgl_po);
        setPilihSpl(response.data.response.supplierId);
        setCart(response.data.result);

        ;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    async function getSuppliers() {
        const response = await axios.get(`https://backendas.agnisurya.com/supplier`);
        setSuppliers(response.data.result);
    }

    // async function getProducts() {
    //     const response = await axios.get(`https://backendas.agnisurya.com/products`);
    //     setQtyBeli(response.data.result);
    // }

    const handleChangeQty = async (id, qt) => {
        let findProductInCart = await cart.find(i => {
            return i.id === id;
        });

        let newCart = [];
        let newItem;

        if (findProductInCart) {
            cart.forEach(cartItem => {
                if (cartItem.id === id) {
                    newItem = {
                        ...cartItem,
                        qty_terima: parseInt(qt),
                    }
                    newCart.push(newItem);
                }
                else {
                    newCart.push(cartItem);
                }
            });
            setCart(newCart);
        }
    }

    const changePageNotTerima = ({ selected }) => {
        setPage(selected);
        if (selected === 9) {
            setMsg(
                "Jika tidak menemukan data yang anda cari, silahkan cari dengan kata kunci yang lebih Spesifik !"
            );
        } else {
            setMsg("");
        }
    };

    const searchDataNotTerima = (e) => {
        e.preventDefault();
        setPage(0);
        setKeyword(queryNotTerima);
    };

    const saveTerimaPO = async (e) => {
        e.preventDefault();

        let stat = 0;
        let stater = 1;
        const formData = new FormData();
        const formData1 = new FormData();

        formData.append("no_terima", noterima);
        formData.append("tgl_terima", tglterima);
        formData.append("no_po", nopo);
        formData.append("tgl_po", tglpo);
        formData.append("status_inv", stat);
        formData.append("supplierId", pilihSpl);
        formData.append("status_terima", stater);

        cart.forEach(async icart => {
            let noter1 = noterima;
            let nopo1 = nopo;
            // let qtyter = parseInt(icart.qty_terima);
            let qtybel = parseInt(icart.product.qty_beli) + parseInt(icart.qty_terima);

            formData1.append("id", icart.id);
            formData1.append("productId", icart.productId);
            formData1.append("satuan", icart.satuan);
            formData1.append("qty", icart.qty_terima);
            formData1.append("hrg_beli", icart.hrg_beli);
            formData1.append("qtybeli", qtybel);
            formData1.append("no_terima", noter1);
            formData1.append("no_po", nopo1);

        })

        try {

            await axios.post("https://backendas.agnisurya.com/poterimadet", formData1, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });

            await axios.post("https://backendas.agnisurya.com/poterima", formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });

            await axios.post(`https://backendas.agnisurya.com/podetupdateqty/${nopo}`, formData1, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });

            await axios.post(`https://backendas.agnisurya.com/poupdatestat/${nopo}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });



            navigate("/poterima");
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    };

    return (
        <div>
            <h1 className="title">Terima PO</h1>
            <h2 className="subtitle">Add New Terima PO</h2>
            <form onSubmit={searchDataNotTerima}>
                <p className="has-text.centered">{msg}</p>
                <div className="columns">
                    <div className="column">
                        <div className="box">
                            <h2 className="subtitle">PO Belum Diterima</h2>
                            {/* <form onSubmit={searchDataNotTerima}> */}
                            {/* onSubmit={searchData} */}
                            <div className="field has-addons">
                                <div className="control is-expanded">
                                    <input
                                        type="text"
                                        className="input"
                                        value={queryNotTerima}
                                        onChange={(e) => setQueryNotTerima(e.target.value)}
                                        placeholder="Find something here ..."
                                    ></input>
                                </div>
                                <div className="control">
                                    <button type="submit" className="button is-info">
                                        Search
                                    </button>
                                </div>
                            </div>
                            {/* </form> */}
                            <div className="box">
                                <table className="table is-striped is-fullwidth">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>No.PO</th>
                                            <th>Tgl.PO</th>
                                            <th>Supplier</th>
                                            <th>Created by</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {poNotTerima ? poNotTerima.map((pnt, index) => {
                                            return (
                                                <tr key={pnt.id} value={pnt.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{pnt.no_po}</td>
                                                    <td>{pnt.tgl_po}</td>
                                                    <td>{pnt.supplier.name}</td>
                                                    <td>{pnt.user.name}</td>
                                                    <td>
                                                        <div className="field is-grouped">
                                                            <p className="control">
                                                                {/* onClick={() => openModal(prd.id, prd.price, prd.quantity)} */}
                                                                <button type="button" className="button is small is-warning" onClick={() => getPOTampil(pnt.no_po)}>
                                                                    Select
                                                                </button>
                                                            </p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }) : <p>Data Tidak Ditemukan</p>}
                                    </tbody>
                                </table>
                                <p>
                                    Total Rows : {rows} Page : {rows ? page + 1 : 0} of {pages}
                                </p>
                                <p className="has-text-centered has-text-danger">{msg}</p>
                                <nav
                                    className="pagination is-centered"
                                    key={rows}
                                    role="navigation"
                                    aria-label="pagination"
                                >
                                    <ReactPaginate
                                        previousLabel={"< Prev"}
                                        nextLabel={"Next"}
                                        pageCount={Math.min(10, pages)}
                                        onPageChange={changePageNotTerima}
                                        containerClassName={"pagination-list"}
                                        pageLinkClassName={"pagination-link"}
                                        previousLinkClassName={"pagination-previous"}
                                        nextLinkClassName={"pagination-next"}
                                        activeLinkClassName={"pagination-link is-current"}
                                        disabledLinkClassName={"pagination-link is-disabled"}
                                    />
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="columns">
                    <div className="column">
                        <div className="box">
                            <h2 className="subtitle">Proses Penerimaan PO</h2>
                            {/* <form> */}
                            <p className="has-text.centered">{msg}</p>
                            <div className="columns">
                                <div className="column">
                                    <div className="box">
                                        <div className="field">
                                            <label className="label">Tgl.Terima</label>
                                            <div className="control">
                                                <input
                                                    type="date"
                                                    className="input"
                                                    value={tglterima}
                                                    onChange={(e) => setTglTerima(e.target.value)}
                                                    placeholder="Tgl.Terima"
                                                />
                                            </div>
                                        </div>
                                        <div className="field">
                                            <label className="label">Supplier</label>
                                            <div className="select is-fullwidth">
                                                <select value={pilihSpl} onChange={(e) => setPilihSpl(e.target.value)}>

                                                    {suppliers.map((spl) => {
                                                        return (
                                                            <option key={spl.id} value={spl.id}>{spl.name}</option>
                                                        );
                                                    })
                                                    }:<option>Pilih supplier</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="box">
                                        <div className="field">
                                            <label className="label">No. PO</label>
                                            <div className="control">
                                                <input
                                                    type="text"
                                                    className="input"
                                                    disabled={true}
                                                    value={nopo}
                                                    onChange={(e) => setNoPO(e.target.value)}
                                                    placeholder="No. PO"
                                                />
                                            </div>
                                        </div>
                                        <div className="field">
                                            <label className="label">Tgl. PO</label>
                                            <div className="control">
                                                <input
                                                    type="date"
                                                    className="input"
                                                    disabled={true}
                                                    value={tglpo}
                                                    onChange={(e) => setTglPO(e.target.value)}
                                                    placeholder="Tgl. PO"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="columns">
                                <div className="column .is-narrow-fullhd">
                                    <div className="box">
                                        <table className="table is-striped is-fullwidth">
                                            <thead>
                                                <tr>
                                                    <th>No</th>
                                                    <th>Kd. Brg</th>
                                                    <th>Nama Produk</th>
                                                    <th>Satuan</th>
                                                    <th>Qty</th>
                                                    <th>Qty. Terima</th>
                                                    <th hidden={true}>Prd.id</th>
                                                    <th hidden={true}>Qty. Beli</th>
                                                    <th hidden={true}>Hrg. Beli</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {cart ? cart.map((prd, index) => {
                                                    return (
                                                        <tr key={prd.id} value={prd.id}>
                                                            <td>{index + 1}</td>
                                                            <td>{prd.product.kd_brg}</td>
                                                            <td>{prd.product.name}</td>
                                                            <td>{prd.satuan}</td>
                                                            <td>{prd.qty}</td>
                                                            <td>{prd.qty_terima}</td>
                                                            <td hidden={true}>{prd.productId}</td>
                                                            <td hidden={true}>{prd.product.qty_beli}</td>
                                                            <td hidden={true}>{prd.hrg_beli}</td>
                                                            <td>
                                                                <div className="field is-grouped">
                                                                    <p className="control">
                                                                        {/* onClick={() => openModal(prd.id, prd.price, prd.quantity)} */}
                                                                        <button
                                                                            type="button"
                                                                            className="button is small is-warning"
                                                                            onClick={() => openModal(prd.id, prd.qty)}>
                                                                            Edit
                                                                        </button>
                                                                    </p>
                                                                    <p className="control">
                                                                        {/* onClick={() => removeProduct(prd)} */}
                                                                        <button type="button" className="button is small is-danger" >
                                                                            Remove
                                                                        </button>
                                                                    </p>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }) : <p>Data Tidak ditemukan</p>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className="field">
                                <div className="control">
                                    {/* onClick={savePODetil} */}
                                    <button type="button" className="button is-success" onClick={saveTerimaPO} >
                                        Save
                                    </button>
                                </div>
                            </div>
                            {/* </form> */}
                        </div>
                    </div>
                </div>
            </form>
            {/* *** MODAL *** */}

            <form>
                <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                    ariaHideApp={false}
                >

                    <h1 className="title" ref={(_subtitle) => (subtitle = _subtitle)}>PO Terima</h1>
                    <h2 className="subtitle" ref={(_subtitle) => (subtitle = _subtitle)}>Edit Qty Terima</h2>
                    <form>
                        <div className="field has-addons">
                            <div className="control">
                                <label
                                    type="text"
                                    className="text"
                                    value={productId}
                                    onChange={(e) => setProductId(e.target.value)}
                                ></label>
                            </div>
                            <div className="control">
                                <input
                                    type="text"
                                    className="input"
                                    value={qtyterima}
                                    onChange={(e) => setQtyTerima(e.target.value)}
                                ></input>
                            </div>
                            <div className="field">
                                <div className="control">
                                    <button type="button" className="button is-success" onClick={() => handleChangeQty(productId, qtyterima)}>
                                        Select
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <button type="button" className="button is-danger mt-3 mb-2 mr-2" onClick={closeModal}>close</button>
                </Modal>
            </form>
        </div>
    )
}

export default FormAddTerimaPO