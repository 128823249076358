import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ReactPaginate from "react-paginate";

const BankList = () => {
    const [bank, setBank] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [pages, setPages] = useState(0);
    const [rows, setRows] = useState(0);
    const [keyword, setKeyword] = useState("");
    const [query, setQuery] = useState("");
    const [msg, setMsg] = useState("");

    useEffect(() => {
        getBank();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, keyword]);

    async function getBank() {
        const response = await axios.get(
            `https://backendas.agnisurya.com/bank?search_query=${keyword}&page=${page}&limit=${limit}`
        );
        setBank(response.data.result);
        setPage(response.data.page);
        setLimit(response.data.limit);
        setPages(response.data.totalPage);
        setRows(response.data.totalRows);
    }

    const deleteBank = async (bankId) => {
        await axios.delete(`https://backendas.agnisurya.com/bank/${bankId}`);
        getBank();
    };

    const changePage = ({ selected }) => {
        setPage(selected);
        if (selected === 9) {
            setMsg(
                "Jika tidak menemukan data yang anda cari, silahkan cari dengan kata kunci yang lebih Spesifik !"
            );
        } else {
            setMsg("");
        }
    };

    const searchData = (e) => {
        e.preventDefault();
        setPage(0);
        setKeyword(query);
    };

    return (
        <div>
            <h1 className="title">Bank</h1>
            <h2 className="subtitle">List of Bank</h2>
            <form onSubmit={searchData}>
                <div className="field has-addons">
                    <Link to="/bank/add" className="button is-primary mb-2 mr-2">
                        Add New
                    </Link>
                    <div className="control is-expanded">
                        <input
                            type="text"
                            className="input"
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            placeholder="Find something here ..."
                        ></input>
                    </div>
                    <div className="control">
                        <button type="submit" className="button is-info">
                            Search
                        </button>
                    </div>
                </div>
            </form>
            <table className="table is-striped is-fullwidth">
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Nama Bank</th>
                        <th>Desc</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {bank.map((bk, index) => (
                        <tr key={bk.uuid}>
                            <td>{index + 1}</td>
                            <td>{bk.name}</td>
                            <td>{bk.desk}</td>
                            <td>
                                <Link
                                    to={`/bank/edit/${bk.uuid}`}
                                    className="button is-small is-info"
                                >
                                    Edit
                                </Link>
                                <button
                                    onClick={() => deleteBank(bk.uuid)}
                                    className="button is-small is-danger"
                                >
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <p>
                Total Rows : {rows} Page : {rows ? page + 1 : 0} of {pages}
            </p>
            <p className="has-text-centered has-text-danger">{msg}</p>
            <nav
                className="pagination is-centered"
                key={rows}
                role="navigation"
                aria-label="pagination"
            >
                <ReactPaginate
                    previousLabel={"< Prev"}
                    nextLabel={"Next"}
                    pageCount={Math.min(10, pages)}
                    onPageChange={changePage}
                    containerClassName={"pagination-list"}
                    pageLinkClassName={"pagination-link"}
                    previousLinkClassName={"pagination-previous"}
                    nextLinkClassName={"pagination-next"}
                    activeLinkClassName={"pagination-link is-current"}
                    disabledLinkClassName={"pagination-link is-disabled"}
                />
            </nav>
        </div>
    );
};

export default BankList