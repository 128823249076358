import axios from 'axios';
import React, { useState, useEffect } from 'react'
import Modal from 'react-modal';
import Select from 'react-select'
import { useNavigate } from "react-router-dom";

const FormAddPurchaseOrder = () => {

    let subtitle;

    const [modalIsOpen, setIsOpen] = useState(false);
    const [products, setProduct] = useState([]);
    // const [keyword, setKeyword] = useState("");
    // const [query, setQuery] = useState("");
    const [suppliers, setSuppliers] = useState([]);
    const [pilihSpl, setPilihSpl] = useState();
    const [nopo, setNoPO] = useState("");
    const [tglpo, setTglPO] = useState("");
    const [total, setTotal] = useState(0);
    // const [nopo1, setNoPO1] = useState("");

    const [productId, setProductId] = useState("");
    // const [satuan, setSatuan] = useState("");
    const [hrgBeli, setHrgBeli] = useState(0);
    const [qty, setQty] = useState(0);
    // const [jmlHrg, setJmlHrg] = useState(0);
    // const [qtyTerima, setQtyTerima] = useState(0);

    const [cart, setCart] = useState([]);
    const navigate = useNavigate();
    const [msg, setMsg] = useState("");
    const [countPO, setCountPO] = useState(0);

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    <Modal className="setAppElement"></Modal>;

    function openModal(id, hr, qt) {
        setProductId(id);
        setQty(qt);
        setHrgBeli(hr);
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }
    function closeModal() {
        setIsOpen(false);
    }

    useEffect(() => {
        getProducts();
        getSuppliers();
        getCount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countPO]);

    useEffect(() => {
        let newTotal = 0;
        cart.forEach(icart => {
            newTotal = newTotal + parseInt(icart.totalAmount);
        })
        setTotal(newTotal);
    }, [cart]);

    async function getSuppliers() {
        const response = await axios.get(
            `https://backendas.agnisurya.com/supplier`
        );
        let date = new Date(), bln = "" + (date.getMonth() + 1), day = "" + (date.getDate()), thn = "" + (date.getFullYear());

        if (day.length < 2) {
            day = '0' + day
        }
        if (bln.length < 2) {
            bln = '0' + bln
        }

        setTglPO([thn + "-" + bln + "-" + day]);
        setSuppliers(response.data.result);

    }

    async function getProducts() {
        // const response = await axios.get('https://backendas.agnisurya.com/products');
        const response = await axios.get(
            `https://backendas.agnisurya.com/products`
        );
        setProduct(response.data.result);
    }

    async function getCount() {
        // const response = await axios.get('https://backendas.agnisurya.com/products');
        const response = await axios.get(
            `https://backendas.agnisurya.com/po`
        );
        setCountPO(response.data.count);
        getNOpo(countPO);
    }

    function getNOpo(e) {
        let nopo1 = 0;
        nopo1 = parseFloat(e + 1).toString().padStart(6, '0');
        let date = new Date(), bln = "" + (date.getMonth() + 1), day = "" + (date.getDate()), thn = "" + (date.getFullYear());

        if (day.length < 2) {
            day = '0' + day
        }
        if (bln.length < 2) {
            bln = '0' + bln
        }

        // nopo.toString().padStart(6, '0')
        setNoPO('PO' + [thn + bln + day] + parseFloat(nopo1).toString().padStart(6, '0'));
        // setNoPO1('PO' + [thn + bln + day] + parseFloat(nopo1).toString().padStart(6, '0'));
    }

    // const addProductToCart = async (prod, id, hr, qt) => {
    //     let findProductInCart = await cart.find(i => {
    //         return i.id === prod.id;
    //     });

    //     if (findProductInCart) {
    //         let newCart = [];
    //         let newItem;

    //         cart.forEach(cartItem => {
    //             if (cartItem.id === prod.id) {
    //                 newItem = {
    //                     ...cartItem,
    //                     quantity: parseInt(qt),
    //                     price: parseInt(hr),
    //                     totalAmount: cartItem.price * (cartItem.quantity + 1),
    //                 }
    //                 newCart.push(newItem);
    //             } else {
    //                 newCart.push(cartItem);
    //             }
    //         });
    //         setCart(newCart);

    //     } else {
    //         let addingProduct = {
    //             ...prod,
    //             'id': prod.id,
    //             'kdbrg': prod.kdbrg,
    //             'name': prod.name,
    //             'satuan': prod.product_satuan.name,
    //             'price': prod.price,
    //             'quantity': 1,
    //             'totalAmount': prod.price
    //         }
    //         setCart([...cart, addingProduct]);
    //     }
    // }

    const addProductToCartFormBarcode = async (prod) => {
        let findProductInCart = await cart.find(i => {
            return i.id === prod.value;
        });

        if (findProductInCart) {
            let newCart = [];
            let newItem;

            cart.forEach(cartItem => {
                if (cartItem.id === prod.value) {
                    newItem = {
                        ...cartItem,
                        quantity: parseInt(cartItem.quantity) + 1,
                        totalAmount: parseInt(cartItem.price) * (parseInt(cartItem.quantity) + 1),
                    }
                    newCart.push(newItem);
                    // setQty(newItem.quantity);
                    // setJmlHrg(newItem.totalAmount);
                } else {
                    newCart.push(cartItem);
                }
            });

            setCart(newCart);

        } else {
            let addingProduct = {
                ...prod,
                'id': prod.value,
                'kdbrg': prod.kdbrg,
                'name': prod.label,
                'satuan': prod.satuan,
                'price': prod.price,
                'quantity': 1,
                'totalAmount': prod.price
            }
            setCart([...cart, addingProduct]);
            // setProductId(addingProduct.id);
            // setSatuan(addingProduct.satuan);
            // setQty(addingProduct.quantity);
            // setHrgBeli(addingProduct.price);
            // setJmlHrg(addingProduct.totalAmount);
            // setQtyTerima(qtyTerima);
        }
    }

    const handleChangeQty = async (id, hr, qt) => {
        let findProductInCart = await cart.find(i => {
            return i.id === id;
        });

        let newCart = [];
        let newItem;

        if (findProductInCart) {
            cart.forEach(cartItem => {
                if (cartItem.id === id) {
                    newItem = {
                        ...cartItem,
                        quantity: parseInt(qt),
                        price: parseInt(hr),
                        totalAmount: parseInt(hr) * parseInt(qt),
                    }
                    newCart.push(newItem);
                }
                else {
                    newCart.push(cartItem);
                }
            });
            setCart(newCart);
        }
    }

    const removeProduct = async (prod) => {
        const newCart = cart.filter(cartItem => cartItem.id !== prod.value);
        setCart(newCart);
    }

    // const savePO = async (e) => {
    //     e.preventDefault();

    //     const formData = new FormData();

    //     formData.append("no_po", nopo);
    //     formData.append("tgl_po", tglpo);
    //     formData.append("supplierId", pilihSpl);
    //     formData.append("nilai_po", total);

    //     try {
    //         await axios.post("https://backendas.agnisurya.com/po", formData, {
    //             headers: {
    //                 "Content-Type": "multipart/form-data"
    //             }
    //         });
    //         // console.log(formData);
    //         navigate("/po");
    //     } catch (error) {
    //         if (error.response) {
    //             setMsg(error.response.data.msg);
    //         }
    //     }
    // };

    const savePODetil = async (e) => {
        e.preventDefault();
        let qtyter = 0;
        let status = 0;
        const formData = new FormData();
        const formData1 = new FormData();
        formData1.append("no_po", nopo);
        formData1.append("tgl_po", tglpo);
        formData1.append("supplierId", pilihSpl);
        formData1.append("nilai_po", total);
        formData1.append("status_terima", status);

        cart.forEach(async icart => {
            let nopo1 = nopo;

            formData.append("productId", icart.value);
            formData.append("satuan", icart.satuan);
            formData.append("qty", icart.quantity);
            formData.append("hrg_beli", icart.price);
            formData.append("jumlah", icart.totalAmount);
            formData.append("qty_terima", qtyter);
            formData.append("no_po", nopo1);
        })

        try {

            await axios.post("https://backendas.agnisurya.com/po", formData1, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });

            await axios.post("https://backendas.agnisurya.com/podet", formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });

            navigate("/po");
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    };


    // const searchData = (e) => {
    //     e.preventDefault();
    //     setKeyword(query);
    // };

    const optionSupplier =
        suppliers ? suppliers.map((spl) => {
            return (
                { value: spl.id, label: spl.name }
            );
        }) : null;


    const optionProduct = products ? products.map((prd) => {
        return (
            { value: prd.id, kdbrg: prd.kd_brg, label: prd.name, satuan: prd.product_satuan.name, price: prd.price, quantity: prd.quantity }
        );
    }) : null;

    return (
        <div>
            <h1 className="title">Purchase Order</h1>
            <h2 className="subtitle">Add New Purchase Order</h2>
            <form>
                <p className="has-text.centered">{msg}</p>
                <div className="columns">
                    <div className="column">
                        <div className="box">
                            {/* <div className="field">
                                <label className="label">No. PO</label>
                                <div className="control">
                                    <input
                                        type="text"
                                        className="input"
                                        value={nopo}
                                        onChange={(e) => setNoPO(e.target.value)}
                                        placeholder="No. PO"
                                    />
                                </div>
                            </div> */}
                            <div className="field">
                                <label className="label">Tgl. PO</label>
                                <div className="control">
                                    <input
                                        type="date"
                                        className="input"
                                        value={tglpo}
                                        onChange={(e) => setTglPO(e.target.value)}
                                        placeholder="Tgl. PO"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column">
                        <div className="box">
                            <div className="field">
                                <label className="label">Supplier</label>
                                <div className="control">
                                    <Select options={optionSupplier} onChange={(e) => setPilihSpl(e.value)} />
                                </div>
                            </div>
                            <div>
                                <h2 className='subtitle ri-separator' > Total : Rp.{total}</h2>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="columns">
                    <div className="column .is-narrow-fullhd">
                        <div className="box">
                            {/* <div className="field has-addons"> */}
                            {/* <div>
                                    <button className="button is-primary mb-2 mr-2" onClick={openModal}>Product</button>
                                </div> */}

                            {/* <div className='field has-addons-right'>
                                    <Select options={optionProduct} onChange={(e) => addProductToCartFormBarcode(e)} />
                                </div>         */}
                            {/* </div> */}
                            <Select options={optionProduct} onChange={(e) => addProductToCartFormBarcode(e)} />
                        </div>
                    </div>
                </div>

                <div className="columns">
                    <div className="column .is-narrow-fullhd">
                        <div className="box">
                            <table className="table is-striped is-fullwidth">
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Kd. Brg</th>
                                        <th>Nama Produk</th>
                                        <th>Satuan</th>
                                        <th>Price</th>
                                        <th>Qty</th>
                                        <th>Jumlah</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cart ? cart.map((prd, index) => {
                                        return (
                                            <tr key={prd.id} value={prd.id}>
                                                <td>{index + 1}</td>
                                                <td>{prd.kdbrg}</td>
                                                <td>{prd.name}</td>
                                                <td>{prd.satuan}</td>
                                                <td>{prd.price}</td>
                                                <td>{prd.quantity}</td>
                                                <td>{prd.totalAmount}</td>
                                                <td>
                                                    <div className="field is-grouped">
                                                        <p className="control">
                                                            <button type="button" className="button is small is-warning" onClick={() => openModal(prd.id, prd.price, prd.quantity)}>
                                                                Edit
                                                            </button>
                                                        </p>
                                                        <p className="control">
                                                            <button type="button" className="button is small is-danger" onClick={() => removeProduct(prd)}>
                                                                Remove
                                                            </button>
                                                        </p>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }) : null}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="field">
                    <div className="control">
                        <button type="button" className="button is-success" onClick={savePODetil}>
                            Save
                        </button>
                    </div>
                </div>
            </form>

            {/* *** MODAL *** */}

            <form>
                <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                    ariaHideApp={false}
                >

                    <h1 className="title" ref={(_subtitle) => (subtitle = _subtitle)}>Products</h1>
                    <h2 className="subtitle" ref={(_subtitle) => (subtitle = _subtitle)}>Edit Price & Qty Product</h2>
                    <form>
                        <div className="field">
                            <div className="control is-expanded">
                                <label
                                    type="text"
                                    className="text"
                                    value={productId}
                                    onChange={(e) => setProductId(e.target.value)}
                                ></label>
                            </div>
                            <div className="control is-expanded">
                                <label className="label">Harga Beli</label>
                                <input
                                    type="text"
                                    className="input"
                                    value={hrgBeli}
                                    onChange={(e) => setHrgBeli(e.target.value)}
                                ></input>
                            </div>
                            <div className="control is-expanded">
                                <label className="label">Quantity</label>
                                <input
                                    type="text"
                                    className="input"
                                    value={qty}
                                    onChange={(e) => setQty(e.target.value)}
                                ></input>
                            </div>

                        </div>
                        <div className="field has-addons">
                            <div className="control is-expanded">
                                <button type="button" className="button is-success" onClick={() => handleChangeQty(productId, hrgBeli, qty)}>
                                    Select
                                </button>
                            </div>

                            <div className="control is-expanded">
                                <button type="button" className="button is-danger" onClick={closeModal}>close</button>
                            </div>
                        </div>

                    </form>

                </Modal>
            </form>
        </div>
    )
}

export default FormAddPurchaseOrder