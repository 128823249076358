import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import axios from "axios";
import { NumericFormat } from 'react-number-format';
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { useReactToPrint } from 'react-to-print';
import { PrintKasir } from './PrintKasir';


const FormKasir = () => {

    // let subtitle;
    const [modalIsOpen, setIsOpen] = useState(false);
    const [salenoyar, setSaleNoYar] = useState([]);
    // const [sale, setSale] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [pages, setPages] = useState(0);
    const [rows, setRows] = useState(0);
    const [keyword, setKeyword] = useState("");
    const [query, setQuery] = useState("");
    const [msg, setMsg] = useState("");
    const navigate = useNavigate();
    const [saleId, setSaleId] = useState("");
    const [nilSale, setNilSale] = useState(0);
    const [nilYar, setNilYar] = useState(0);
    const componentRef = useRef();
    const [bayarJenis, setBayarJenis] = useState([]);
    const [pilihBayarJenis, setPilihBayarJenis] = useState("");
    const [bank, setBank] = useState([]);
    const [pilihBank, setPilihBank] = useState("");
    const [nilkem, setNilkem] = useState(0);

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    <Modal className="setAppElement"></Modal>;

    function openModal(id, hr) {
        setSaleId(id);
        setNilSale(hr);
        setIsOpen(true);
    }

    // function afterOpenModal() {
    //     // references are now sync'd and can be accessed.
    //     subtitle.style.color = '#f00';
    // }

    function closeModal() {
        setIsOpen(false);
        handlePrint();
    }

    useEffect(() => {
        getSaleNoYar();
        getBayarJenis();
        getBank();
        // getSale();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, keyword]);

    async function getBayarJenis() {
        const response = await axios.get(
            `https://backendas.agnisurya.com/jenisbyr`
        );
        setBayarJenis(response.data.result);
    }

    async function getBank() {
        const response = await axios.get(
            `https://backendas.agnisurya.com/bank`
        );
        setBank(response.data.result);
    }

    async function getSaleNoYar() {
        const response = await axios.get(
            `https://backendas.agnisurya.com/salenoyar?search_query=${keyword}&page=${page}&limit=${limit}`
        );
        setSaleNoYar(response.data.result);
        setPage(response.data.page);
        setLimit(response.data.limit);
        setPages(response.data.totalPage);
        setRows(response.data.totalRows);
    }

    const changePage = ({ selected }) => {
        setPage(selected);
        if (selected === 9) {
            setMsg(
                "Jika tidak menemukan data yang anda cari, silahkan cari dengan kata kunci yang lebih Spesifik !"
            );
        } else {
            setMsg("");
        }
    };

    const searchData = (e) => {
        e.preventDefault();
        setPage(0);
        setKeyword(query);
    };

    // async function getSale() {
    //     const response = await axios.get(
    //         `https://backendas.agnisurya.com/sale`
    //     );
    //     setSale(response.data.result);
    //     // setPage(response.data.page);
    //     // setLimit(response.data.limit);
    //     // setPages(response.data.totalPage);
    //     // setRows(response.data.totalRows);
    // }

    const updateSale = async () => {
        try {
            // console.log(saleId);
            // await axios.post(`https://backendas.agnisurya.com/sale/${saleId}`, {
            //     no_sale: saleId,
            //     nilai_sale: nilSale
            // });

            navigate("/kasir");

        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    };

    const handleNilyar = (e) => {
        setNilYar(e);
        let nil1 = parseInt(nilSale);
        let nil = prompt(e);
        let hasil = Number(nil) - Number(nil1);
        // Kembalian(nilYar);
        setNilYar(parseInt(nil));
        setNilkem(hasil);
    }

    // const Kembalian = (e) => {
    //     let nil1 = parseInt(nilSale);
    //     let nil = parseFloat(e);
    //     let hasil = Number(nil) - Number(nil1);
    //     // setNilYar(parseInt(nil));
    //     setNilkem(hasil);
    // }

    const handleReactToPrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handlePrint = () => {
        handleReactToPrint();
        updateSale();
    }

    return (
        <div>
            <h1 className="title">AGNI SURYA</h1>
            <h2 className="subtitle">CASHIER</h2>
            <div className="box">
                <form onSubmit={searchData}>
                    {/* onSubmit={searchData} */}
                    <p className="has-text.centered">{msg}</p>
                    <div className="field has-addons">
                        <div className="control is-expanded">
                            <input
                                type="text"
                                className="input"
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                                placeholder="Find something here ..."
                            ></input>
                        </div>
                        <div className="control">
                            <button type="submit" className="button is-info">
                                Search
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div className="box">
                <div style={{ display: "none" }}>
                    <PrintKasir saleId={saleId} nilSale={nilSale} ref={componentRef} />
                </div>
                <table className="table is-striped is-fullwidth">
                    <thead>
                        <tr>
                            <th>No</th>
                            <th>No Trans</th>
                            <th>Tgl. Trans</th>
                            <th>Nilai Trans</th>
                            <th>Created By</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {salenoyar.map((sny, index) => (
                            <tr key={sny.uuid}>
                                <td>{index + 1}</td>
                                <td>{sny.no_sale}</td>
                                <td>{sny.tgl_sale}</td>
                                <td>{sny.nilai_sale}</td>
                                <td>{sny.user.name}</td>
                                <td>
                                    <div className="field is-grouped">
                                        <p className="control">
                                            <button type="button" className="button is small is-warning" onClick={() => openModal(sny.no_sale, sny.nilai_sale)}>
                                                Payout
                                            </button>
                                        </p>
                                        <p className="control">
                                            <button type="button" className="button is small is-primary" onClick={handlePrint}>
                                                Print
                                            </button>
                                        </p>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <p>
                    Total Rows : {rows} Page : {rows ? page + 1 : 0} of {pages}
                </p>
                <p className="has-text-centered has-text-danger">{msg}</p>
                <nav
                    className="pagination is-centered"
                    key={rows}
                    role="navigation"
                    aria-label="pagination"
                >
                    <ReactPaginate
                        previousLabel={"< Prev"}
                        nextLabel={"Next"}
                        pageCount={Math.min(10, pages)}
                        onPageChange={changePage}
                        containerClassName={"pagination-list"}
                        pageLinkClassName={"pagination-link"}
                        previousLinkClassName={"pagination-previous"}
                        nextLinkClassName={"pagination-next"}
                        activeLinkClassName={"pagination-link is-current"}
                        disabledLinkClassName={"pagination-link is-disabled"}
                    />
                </nav>
            </div>

            {/* *** MODAL *** */}

            <form>
                <Modal
                    isOpen={modalIsOpen}
                    // onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                    ariaHideApp={false}
                >
                    <form onSubmit={closeModal}>
                        <div className="modal-card">
                            <header className="modal-card-head">
                                <p className="modal-card-title">Casshier</p>
                                <button className="delete" aria-label="close"></button>
                            </header>
                            <section className="modal-card-body">
                                <div>
                                    <label className='label'>No. Transaksi</label>
                                    <input
                                        className="input is-medium"
                                        type="text"
                                        placeholder="Medium input"
                                        value={saleId}
                                        disabled={true}>
                                    </input>
                                </div>
                                <div>
                                    <label className='label'>Nilai Transaksi</label>
                                    <NumericFormat
                                        className='input is-medium'
                                        value={nilSale}
                                        allowLeadingZeros
                                        thousandSeparator=","
                                        prefix={'Rp. '}
                                        disabled={true}
                                    />
                                </div>
                                <div className="field">
                                    <label className="label">Jenis Pembayaran</label>
                                    <div className="control">
                                        <div className="select is-fullwidth">
                                            <select value={pilihBayarJenis} onChange={(e) => setPilihBayarJenis(e.target.value)}>
                                                {bayarJenis ? bayarJenis.map((byr) => {
                                                    return (
                                                        <option key={byr.id} value={byr.id}>{byr.name}</option>
                                                    );
                                                })
                                                    : null}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                {
                                    pilihBayarJenis.name !== 'Cash' ? <div>
                                        <div className="field">
                                            <label className="label">Bank</label>
                                            <div className="control">
                                                <div className="select is-fullwidth">
                                                    <select value={pilihBank} onChange={(e) => setPilihBank(e.target.value)}>
                                                        {bank ? bank.map((bnk) => {
                                                            return (
                                                                <option key={bnk.id} value={bnk.id}>{bnk.name}</option>
                                                            );
                                                        })
                                                            : null}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : "Pembayaran CASH"
                                }

                                <div>
                                    <label className='label'>Nilai Bayar</label>
                                    <input
                                        className="input is-large"
                                        type="currency"
                                        placeholder="Nilai Bayar"
                                        value={nilYar}
                                        onChange={(e) => handleNilyar(e.value)}
                                    />
                                    {/* onChange={(e) => setNilYar(e)} */}
                                </div>
                                <div>
                                    <label className='label'>Kembali</label>
                                    <input
                                        className="input is-large"
                                        type="currency"
                                        placeholder="Kembali"
                                        value={nilkem}
                                    />
                                    {/* onChange={(e) => setNilYar(e)} */}
                                </div>

                            </section>
                            <footer className="modal-card-foot">
                                <button className="submit button is-success" onClick={(e) => updateSale(saleId)}>Save</button>
                                {/* <button className="submit" onClick={closeModal}>Cancel</button> */}
                            </footer>
                        </div>

                    </form>
                    {/* <button type="button" className="button is-danger" onClick={closeModal}>close</button>
                    <button type="button" className="button is-success" >Save</button> */}
                </Modal>
            </form>
        </div>
    )
}

export default FormKasir