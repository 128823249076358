import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const FormAddCompany = () => {
    const [name, setName] = useState("");
    const [alamat, setAlamat] = useState("");
    const [phone, setPhone] = useState("");
    const [desk, setDesk] = useState("");
    const [companyJenis, setCompanyJenis] = useState([]);
    const [pilihCompJenis, setPilihCompJenis] = useState();
    const [msg, setMsg] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        getCompanyJenis();
    });

    async function getCompanyJenis() {
        const response = await axios.get(
            `https://backendas.agnisurya.com/companyjenis`
        );
        setCompanyJenis(response.data.result);
    }

    const saveCompany = async (e) => {
        e.preventDefault();
        try {
            await axios.post("https://backendas.agnisurya.com/companys", {
                name: name,
                alamat: alamat,
                phone: phone,
                desk: desk,
                jenisId: pilihCompJenis,
            });
            navigate("/companys");
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    };

    return (
        <div>
            <h1 className="title">Company</h1>
            <h2 className="subtitle">Add New Company</h2>
            <div className="card is-shadowsless">
                <div className="card-content">
                    <div className="content">
                        <form onSubmit={saveCompany}>
                            <p className="has-text.centered">{msg}</p>
                            <div className="field">
                                <label className="label">Company Name</label>
                                <div className="control">
                                    <input
                                        type="text"
                                        className="input"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        placeholder="Company Name"
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Alamat</label>
                                <div className="control">
                                    <input
                                        type="text"
                                        className="input"
                                        value={alamat}
                                        onChange={(e) => setAlamat(e.target.value)}
                                        placeholder="Alamat"
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Phone</label>
                                <div className="control">
                                    <input
                                        type="text"
                                        className="input"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        placeholder="Phone"
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Deskripsi</label>
                                <div className="control">
                                    <input
                                        type="text"
                                        className="input"
                                        value={desk}
                                        onChange={(e) => setDesk(e.target.value)}
                                        placeholder="Deskripsi"
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Company Jenis</label>
                                <div className="control">
                                    <div className="select is-fullwidth">
                                        <select value={pilihCompJenis} onChange={(e) => setPilihCompJenis(e.target.value)}>
                                            <option value="default">Pilih Nama Perusahaan</option>
                                            {companyJenis ? companyJenis.map((comp) => {
                                                return (
                                                    <option key={comp.id} value={comp.id}>{comp.name}</option>
                                                );
                                            })
                                                : null}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="field">
                                <div className="control">
                                    <button type="submit" className="button is-success">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormAddCompany;
