import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const FormAddProduct = () => {
    const [kdbrg, setKdbrg] = useState("");
    const [name, setName] = useState("");
    const [hrg_beli, setHrgBeli] = useState("");
    const [price, setPrice] = useState("");
    const [qty_awal, setQtyAwal] = useState("");
    const [qty_beli, setQtyBeli] = useState("");
    const [qty_retbeli, setQtyRetBeli] = useState("");
    const [qty_jual, setQtyJual] = useState("");
    const [qty_retjual, setQtyRetJual] = useState("");
    const [qty_ready, setQtyReady] = useState("");
    const [productJenis, setProductJenis] = useState([]);
    const [pilihProdJenis, setPilihProdJenis] = useState();
    const [productSat, setProductSat] = useState([]);
    const [pilihProdSat, setPilihProdSat] = useState();
    const [file, setFile] = useState("");
    const [preview, setPreview] = useState("");
    const [msg, setMsg] = useState("");
    const navigate = useNavigate();
    const [countPrd, setCountPrd] = useState(0);

    useEffect(() => {
        getProductJenis();
        getProductSatuan();
        getCount();
        // eslint-disable-next-line
    }, [countPrd]);

    async function getProductJenis() {
        const response = await axios.get(
            `https://backendas.agnisurya.com/productJenis`
        );
        setProductJenis(response.data.result);
    }

    async function getProductSatuan() {
        const response = await axios.get(
            `https://backendas.agnisurya.com/productSatuan`
        );
        setProductSat(response.data.result);
    }

    async function getCount() {
        // const response = await axios.get('https://backendas.agnisurya.com/products');
        const response = await axios.get(
            `https://backendas.agnisurya.com/products`
        );
        setCountPrd(response.data.count);
        getKdPrd();
    }

    function getKdPrd() {
        // let kd = 0;
        // kd = parseFloat(e + 1).toString().padStart(6, '0');
        // let date = new Date(), bln = "" + (date.getMonth() + 1), day = "" + (date.getDate()), thn = "" + (date.getFullYear());

        // if (day.length < 2) {
        //     day = '0' + day
        // }
        // if (bln.length < 2) {
        //     bln = '0' + bln
        // }

        // nopo.toString().padStart(6, '0')
        let idJen = 1;
        let noUrut = countPrd + 1;
        setKdbrg(idJen + noUrut.toString().padStart(6, '0'));
        // setNoPO1('PO' + [thn + bln + day] + parseFloat(nopo1).toString().padStart(6, '0'));
    }

    const saveProduct = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("kdbrg", kdbrg);
        formData.append("name", name);
        formData.append("hrg_beli", hrg_beli);
        formData.append("price", price);
        formData.append("qty_awal", qty_awal);
        formData.append("qty_beli", qty_beli);
        formData.append("qty_retbeli", qty_retbeli);
        formData.append("qty_jual", qty_jual);
        formData.append("qty_retjual", qty_retjual);
        formData.append("qty_ready", qty_ready);
        formData.append("jenis", pilihProdJenis);
        formData.append("satuan", pilihProdSat);
        formData.append("file", file);
        try {
            await axios.post("https://backendas.agnisurya.com/products", formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });
            navigate("/products");
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    };

    const loadImage = (e) => {
        const image = e.target.files[0];
        setFile(image);
        setPreview(URL.createObjectURL(image));
    }

    return (
        <div>
            <h1 className="title">Products</h1>
            <h2 className="subtitle">Add New Products</h2>
            <div className="card is-shadowsless">
                <div className="card-content">
                    <div className="content">
                        <form onSubmit={saveProduct}>
                            <p className="has-text.centered">{msg}</p>
                            <div className="field">
                                <label className="label">Kode Barang</label>
                                <div className="control">
                                    <input
                                        type="text"
                                        className="input"
                                        value={kdbrg}
                                        onChange={(e) => setKdbrg(e.target.value)}
                                        placeholder="Kode Barang"
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Nama Barang</label>
                                <div className="control">
                                    <input
                                        type="text"
                                        className="input"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        placeholder="Nama Barang"
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Jenis</label>
                                <div className="control">
                                    <div className="select is-fullwidth">
                                        <select value={pilihProdJenis} onChange={(e) => setPilihProdJenis(e.target.value)}>
                                            <option value="default">Pilih Jenis Product</option>
                                            {productJenis ? productJenis.map((prodjen) => {
                                                return (
                                                    <option key={prodjen.id} value={prodjen.id}>{prodjen.name}</option>
                                                );
                                            })
                                                : null}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Satuan</label>
                                <div className="control">
                                    <div className="select is-fullwidth">
                                        <select value={pilihProdSat} onChange={(e) => setPilihProdSat(e.target.value)}>
                                            <option value="default">Pilih Satuan Product</option>
                                            {productSat ? productSat.map((prodsat) => {
                                                return (
                                                    <option key={prodsat.id} value={prodsat.id}>{prodsat.name}</option>
                                                );
                                            })
                                                : null}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Harga Pembelian</label>
                                <div className="control">
                                    <input
                                        type="text"
                                        className="input"
                                        value={hrg_beli}
                                        onChange={(e) => setHrgBeli(e.target.value)}
                                        placeholder="Purchase Price"
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Harga Penjualan</label>
                                <div className="control">
                                    <input
                                        type="text"
                                        className="input"
                                        value={price}
                                        onChange={(e) => setPrice(e.target.value)}
                                        placeholder="Price"
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Qty Awal</label>
                                <div className="control">
                                    <input
                                        type="text"
                                        className="input"
                                        value={qty_awal}
                                        onChange={(e) => setQtyAwal(e.target.value)}
                                        placeholder="Qty Awal"
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Qty Beli</label>
                                <div className="control">
                                    <input
                                        type="text"
                                        className="input"
                                        value={qty_beli}
                                        onChange={(e) => setQtyBeli(e.target.value)}
                                        placeholder="Qty Beli"
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Qty Retur Beli</label>
                                <div className="control">
                                    <input
                                        type="text"
                                        className="input"
                                        value={qty_retbeli}
                                        onChange={(e) => setQtyRetBeli(e.target.value)}
                                        placeholder="Qty Retur Beli"
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Qty Jual</label>
                                <div className="control">
                                    <input
                                        type="text"
                                        className="input"
                                        value={qty_jual}
                                        onChange={(e) => setQtyJual(e.target.value)}
                                        placeholder="Qty Jual"
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Qty Retur Jual</label>
                                <div className="control">
                                    <input
                                        type="text"
                                        className="input"
                                        value={qty_retjual}
                                        onChange={(e) => setQtyRetJual(e.target.value)}
                                        placeholder="Qty Retur Jual"
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Qty Ready</label>
                                <div className="control">
                                    <input
                                        type="text"
                                        className="input"
                                        value={qty_ready}
                                        onChange={(e) => setQtyReady(e.target.value)}
                                        placeholder="Qty Ready"
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Image</label>
                                <div className="control">
                                    <div className="file">
                                        <label className="file-label">
                                            <input type="file" className="file-input" onChange={loadImage} />
                                            <span className="file-cta">
                                                <span className="file-label">Choose a file...</span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            {preview ? (
                                <figure className="image is-128x128">
                                    <img src={preview} alt="Preview Gambar" />
                                </figure>
                            ) : ("")}

                            <div className="field">
                                <div className="control">
                                    <button type="submit" className="button is-success">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormAddProduct;
