import React, { useEffect } from "react";
import Layout from "../../layout";
import FormAddCompanyJenis from "../../../components/company/companyjenis/FormAddCompanyJenis";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe } from "../../../features/authSlice";

const AddCompanyJenis = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isError } = useSelector((state => state.auth));

    useEffect(() => {
        dispatch(getMe());
    }, [dispatch]);

    useEffect(() => {
        if (isError) {
            navigate("/");
        }
    }, [isError, navigate]);

    return (
        <Layout>
            <FormAddCompanyJenis />
        </Layout>
    )
}

export default AddCompanyJenis