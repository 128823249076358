import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { IoPerson, IoPricetag, IoHome, IoLogOut, IoBusinessOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { LogOut, reset } from "../features/authSlice";

const Sidebar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.auth);

    const logout = () => {
        dispatch(LogOut());
        dispatch(reset());
        navigate("/");
    };

    return (
        <div>
            <aside className="menu pl-2 hash-shadow">
                <p className="menu-label"> General</p>
                <ul className="menu-list">
                    <li>
                        <NavLink to={"/dashboard"}>
                            <IoHome /> Dashboard
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={"/companys"}>
                            <IoBusinessOutline /> Company
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={"/companyjenis"}>
                            <IoBusinessOutline /> Company Jenis
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={"/products"}>
                            <IoPricetag /> Product
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={"/productJenis"}>
                            <IoPricetag /> Product Jenis
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={"/productSatuan"}>
                            <IoPricetag /> Product Satuan
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={"/bank"}>
                            <IoPerson /> Bank
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={"/jenisbyr"}>
                            <IoPerson /> Jenis Pembayaran
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={"/customer"}>
                            <IoPerson /> Customers
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={"/supplier"}>
                            <IoPerson /> Suppliers
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={"/karyawan"}>
                            <IoPerson /> Karyawan
                        </NavLink>
                    </li>
                </ul>
                <p className="menu-label"> Transaction</p>
                <ul className="menu-list">
                    <p className="menu-label"> Purchasing</p>
                    <li>
                        <NavLink to={"/po"}>
                            <IoPerson /> Purchase Orders
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={"/poinv"}>
                            <IoPerson /> Invoice
                        </NavLink>
                    </li>
                </ul>

                <ul className="menu-list">
                    <p className="menu-label"> Sale</p>
                    <li>
                        <NavLink to={"/sale"}>
                            <IoPerson /> Sales
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={"/kasir"}>
                            <IoPerson /> Cash Register
                        </NavLink>
                    </li>
                </ul>

                <p className="menu-label"> Warehouse</p>
                <ul className="menu-list">
                    <li>
                        <NavLink to={"/poterima"}>
                            <IoPerson /> Terima PO
                        </NavLink>
                    </li>

                    <li>
                        <NavLink to={"/stokopname"}>
                            <IoPerson /> Stok Opname
                        </NavLink>
                    </li>

                </ul>

                <p className="menu-label"> Report</p>
                <ul className="menu-list">
                    <li>
                        <NavLink to={"/rptmasterbrg"}>
                            <IoPerson /> Report Product
                        </NavLink>
                    </li>

                    <li>
                        <NavLink to={"/rptpoinv"}>
                            <IoPerson /> Report Purchase Order
                        </NavLink>
                    </li>

                    <li>
                        <NavLink to={"/rptsale"}>
                            <IoPerson /> Report Sales
                        </NavLink>
                    </li>

                </ul>

                <p className="menu-label"> Settings</p>
                <ul className="menu-list">
                    {user && user.role === "admin" && (
                        <div>
                            <li>
                                <NavLink to={"/users"}>
                                    <IoPerson /> Users
                                </NavLink>
                            </li>
                        </div>
                    )}
                    <li>
                        <button onClick={logout} className="button is-white">
                            <IoLogOut /> Logout
                        </button>
                    </li>

                </ul>
            </aside>

            {/* ** Awal Menu ** */}
            {/* <div class="select">
                <select>
                    <option>Select dropdown</option>
                    <option>With options</option>
                </select>
            </div> */}
            {/* ** Akhir Menu ** */}

        </div>
    );
};

export default Sidebar;
