// AuthMiddleware.js
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMe, reset } from '../features/authSlice';
const apiUrl = process.env.REACT_APP_API_URL;

const useAuthMiddleware = () => {
    const [backendError, setBackendError] = useState(null);
    const dispatch = useDispatch();
    
  

    const checkBackendConnection = async () => {
        try {
            await fetch(apiUrl);
            setBackendError(null);
        } catch (error) {
            console.error('Failed to connect to the backend:', error);
            setBackendError('Unable to connect to the server. Please try again later.');
        }
    };

    const checkAuth = async () => {
        dispatch(getMe());
    };

    useEffect(() => {
        const fetchData = async () => {
            await checkBackendConnection();
            await checkAuth();
        };

        fetchData();

        return () => {
            dispatch(reset());
        };
    }, [dispatch]);
    return { checkAuth, backendError, setBackendError };
};

export default useAuthMiddleware;
