import React from 'react'

export const PrintKasir = React.forwardRef((props, ref) => {
    return (
        <div ref={ref}>
            <h1 className="title">AGNI SURYA</h1>
            <h2 className="subtitle">CASHIER</h2>
            <table className="table table-responsive table-dark table-hover">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Nama Produk</th>
                        <th>Satuan</th>
                        <th>Price</th>
                        <th>Qty</th>
                        <th>Jumlah</th>
                    </tr>
                </thead>
                <tbody>

                </tbody>
            </table>
        </div>
    );
});
