import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const FormAddKaryawan = () => {
    const [name, setName] = useState("");
    const [alamat, setAlamat] = useState("");
    const [tempat_lahir, setTempatLahir] = useState("");
    const [tgl_lahir, setTglLahir] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [piutang, setPiutang] = useState("");
    const [tgl_masuk, setTglMasuk] = useState("");
    const [tgl_resign, setTglResign] = useState("");
    const [desk, setDesk] = useState("");
    const [file, setFile] = useState("");
    const [preview, setPreview] = useState("");
    const [msg, setMsg] = useState("");
    const navigate = useNavigate();

    const saveKarywan = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("name", name);
        formData.append("alamat", alamat);
        formData.append("tempat_lahir", tempat_lahir);
        formData.append("tgl_lahir", tgl_lahir);
        formData.append("phone", phone);
        formData.append("email", email);
        formData.append("piutang", piutang);
        formData.append("tgl_masuk", tgl_masuk);
        formData.append("tgl_resign", tgl_resign);
        formData.append("desk", desk);
        formData.append("file", file);
        try {
            await axios.post("https://backendas.agnisurya.com/karyawan", formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });
            navigate("/karyawan");
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    };

    const loadImage = (e) => {
        const image = e.target.files[0];
        setFile(image);
        setPreview(URL.createObjectURL(image));
    }

    return (
        <div>
            <h1 className="title">Karyawan</h1>
            <h2 className="subtitle">Add New Karyawan</h2>
            <div className="card is-shadowsless">
                <div className="card-content">
                    <div className="content">
                        <form onSubmit={saveKarywan}>
                            <p className="has-text.centered">{msg}</p>
                            <div className="field">
                                <label className="label">Name</label>
                                <div className="control">
                                    <input
                                        type="text"
                                        className="input"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        placeholder="Name"
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Alamat</label>
                                <div className="control">
                                    <input
                                        type="text"
                                        className="input"
                                        value={alamat}
                                        onChange={(e) => setAlamat(e.target.value)}
                                        placeholder="Alamat"
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Tempat Lahir</label>
                                <div className="control">
                                    <input
                                        type="text"
                                        className="input"
                                        value={tempat_lahir}
                                        onChange={(e) => setTempatLahir(e.target.value)}
                                        placeholder="Tempat Lahir"
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Tgl. Lahir</label>
                                <div className="control">
                                    <input
                                        type="date"
                                        className="input"
                                        value={tgl_lahir}
                                        onChange={(e) => setTglLahir(e.target.value)}
                                        placeholder="Tanggal Lahir"
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Phone</label>
                                <div className="control">
                                    <input
                                        type="text"
                                        className="input"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        placeholder="Phone"
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Email</label>
                                <div className="control">
                                    <input
                                        type="text"
                                        className="input"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder="Email"
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Piutang</label>
                                <div className="control">
                                    <input
                                        type="text"
                                        className="input"
                                        value={piutang}
                                        onChange={(e) => setPiutang(e.target.value)}
                                        placeholder="Piutang"
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Tgl. Masuk</label>
                                <div className="control">
                                    <input
                                        type="date"
                                        className="input"
                                        value={tgl_masuk}
                                        onChange={(e) => setTglMasuk(e.target.value)}
                                        placeholder="Tgl. Masuk"
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Tgl. Resign</label>
                                <div className="control">
                                    <input
                                        placeholder="Tgl. Resign"
                                        className="input"
                                        type="date"
                                        value={tgl_resign}
                                        onChange={(e) => setTglResign(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Deskripsi</label>
                                <div className="control">
                                    <input
                                        type="text"
                                        className="input"
                                        value={desk}
                                        onChange={(e) => setDesk(e.target.value)}
                                        placeholder="Deskripsi"
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Foto</label>
                                <div className="control">
                                    <div className="file">
                                        <label className="file-label">
                                            <input type="file" className="file-input" onChange={loadImage} />
                                            <span className="file-cta">
                                                <span className="file-label">Choose a file...</span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            {preview ? (
                                <figure className="image is-128x128">
                                    <img src={preview} alt="Preview Gambar" />
                                </figure>
                            ) : ("")}

                            <div className="field">
                                <div className="control">
                                    <button type="submit" className="button is-success">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormAddKaryawan;
